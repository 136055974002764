import { configureStore } from "@reduxjs/toolkit";
import bookSlice from "./features/bookSlice";
import openaiSlice from "./features/openaiSlice";
import uiSlice from "./features/uiSlice";
import questionSlice from "./features/questionSlice";
import userSlice from "./features/userSlice";
import modelSlice from "./features/modelSlice";
import blurbSlice from "./features/blurbSlice";

export default configureStore({
  reducer: {
    bookSlice,
    uiSlice,
    openaiSlice,
    questionSlice,
    userSlice,
    modelSlice,
    blurbSlice,
  },
});
