import { Button } from "@mui/material";
import React from "react";

export default function DialogExportJSONL({ questions }) {
  const data = questions.map((question) => {
    return {
      messages: question.messages.map(({ role, generation, history }) => {
        const content = history[generation].content;
        return { role, content };
      }),
    };
  });
  const exportQuestionsToJSONL = () => {
    const jsonlContent = data
      .map((question) => JSON.stringify(question))
      .join("\n");
    const blob = new Blob([jsonlContent], { type: "application/x-ndjson" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "questions.jsonl";
    link.click();
  };
  return (
    <Button
      variant="text"
      size="small"
      style={{ textTransform: "none" }}
      onClick={() => {
        exportQuestionsToJSONL();
      }}
    >
      Export JSONL
    </Button>
  );
}
