import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, post, put } from "aws-amplify/api";
import { LAMBDA_ARNS, invokeLambda } from "../utils";

export const getMyModels = createAsyncThunk("my/models/get", async () => {
  const res = await invokeLambda(LAMBDA_ARNS.readerUtils, "getModelList");
  return res.data;
});

export const modelAdd = createAsyncThunk("model/add", async (model) => {
  console.log(model);
  try {
    const res = await post({
      apiName: "readerModels",
      path: "/models",
      options: {
        body: { model },
      },
    });
    // const { error, data } = await res.body.json();
    console.log("res", res);
  } catch (err) {
    console.log(err);
  }
  return model;
});

export const modelRemove = createAsyncThunk(
  "model/remove",
  async (id, thunkApi) => {
    console.log(id);
    try {
      const res = await del({
        apiName: "readerModels",
        path: "/models",
        options: {
          queryParams: { id },
        },
      });
      console.log("res", res);
    } catch (err) {
      console.log(err);
    }
    return id;
  }
);

export const modelSlice = createSlice({
  name: "modelSlice",
  initialState: {
    models: [],
    model: "none",
  },
  reducers: {
    setModel: (state, action) => {
      state.model = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyModels.fulfilled, (state, action) => {
      state.models = action.payload;
    });
    builder.addCase(modelAdd.fulfilled, (state, action) => {
      state.models.push(action.payload);
    });
    builder.addCase(modelRemove.fulfilled, (state, action) => {
      state.models.splice(
        state.models.findIndex((n) => n.id === action.payload),
        1
      );
    });
  },
});

export const { setModel, setSelections } = modelSlice.actions;
export default modelSlice.reducer;
