import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { batchPutDynamo } from "../../features/questionSlice";

export default function DialogImportJSONL() {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [dataset, setDataset] = useState("");
  const [title, setTitle] = useState("");
  const [info, setInfo] = useState("");
  // const [book, setBook] = useState("none");
  const book = useSelector((state) => state.bookSlice.book);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleImport = async () => {
    if (!dataset) {
      toast.warn("You need to fill out the dataset field");
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const jsonl = e.target.result;
        const json = jsonl.split("\n").filter(Boolean).map(JSON.parse);
        dispatch(batchPutDynamo({ items: json, dataset, title, info, book }));
      };
      reader.readAsText(file);
    }
    handleClose();
  };

  return (
    <div>
      <Button
        variant="text"
        size="small"
        style={{ textTransform: "none" }}
        onClick={handleOpen}
      >
        Import JSONL
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Import JSONL</DialogTitle>
        <DialogContent>
          {/* <FormControl fullWidth size="small"> */}
          {/* <InputLabel id="book-select-label">Select Book</InputLabel> */}
          {/* <Select
              placeholder="select book"
              // labelId="book-select-label"
              value={book}
              onChange={(e) => {
                setBook(e.target.value);
              }}
            >
              {books.map((_book, index) => (
                <MenuItem key={index} value={_book.key}>
                  {_book.key}
                </MenuItem>
              ))}
            </Select> */}
          {/* </FormControl> */}
          <TextField
            placeholder="which dataset does this belong too?"
            autoFocus
            margin="dense"
            label="Dataset"
            type="text"
            fullWidth
            value={dataset}
            onChange={(e) => setDataset(e.target.value)}
          />
          <TextField
            placeholder="Pretty title for the dataset"
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            placeholder="Any additional info about this set"
            margin="dense"
            label="Info"
            type="text"
            fullWidth
            value={info}
            onChange={(e) => setInfo(e.target.value)}
          />
          <input type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleImport} color="primary">
            Import
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
