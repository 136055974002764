import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post, put } from "aws-amplify/api";
import { LAMBDA_ARNS, invokeLambda } from "../utils";
import { toast } from "react-toastify";

export const questionsGet = createAsyncThunk("questions/get", async () => {
  // const result = await API.get("reader7cecfdf3", "/questions");
  // return result.questions;

  try {
    const restOperation = get({
      // apiName: "reader7cecfdf3",
      apiName: "readerapi",
      path: "/questions",
    });
    const res = await restOperation.response;
    const { error, questions } = await res.body.json();
    if (error) {
      console.log(data.error);
    } else {
      return questions;
    }
    return [];
  } catch (e) {
    console.log("GET call failed: ", JSON.parse(e.response.body));
  }
});
export const questionAdd = createAsyncThunk(
  "question/add",
  async ({ question }, thunkApi) => {
    const user = thunkApi.getState().userSlice.user;
    question.user = user.email;
    try {
      const res = await post({
        apiName: "readerapi",
        path: "/questions",
        options: {
          body: { question },
        },
      });
      console.log("res", res);
    } catch (err) {
      console.log(err);
    }
    return question;
  }
);

// This is really a query into the book, not so much an answer
export const questionAddAnswer = createAsyncThunk(
  "question/add/answer",
  async ({ id, answer }, thunkApi) => {
    const user = thunkApi.getState().userSlice.user;
    answer.user = user.email;
    answer.role = "user";
    answer.content = answer.answer;

    const res = await put({
      apiName: "readerapi",
      path: `/questions/${id}`,
      options: {
        body: { answer },
      },
    }).response;
    const { updated } = await res.body.json();
    return { id, items: updated };
  }
);

export const removeMessage = createAsyncThunk(
  "message/remove",
  async ({ messageId, questionId }) => {
    await invokeLambda(LAMBDA_ARNS.readerUtils, "removeMessage", {
      id: questionId,
      messageId,
    });
    return { messageId, questionId };
  }
);

export const updateMessageAsync = createAsyncThunk(
  "message/update/async",
  async ({ message, questionId, generation }) => {
    // You have to return the new messsage to the redux reducer because it has the history array on it.
    toast.info("Content update started");
    const { message: newMessage } = await invokeLambda(
      LAMBDA_ARNS.readerUtils,
      "updateMessage",
      {
        id: questionId,
        message,
        generation,
      }
    );
    toast.success("Content update complete");

    return { questionId, message: newMessage, generation };
  }
);

export const batchPutDynamo = createAsyncThunk(
  "batch/put/dynamo",
  // async ({ items, dataset, title, info, book }) => {
  async (body, thunkApi) => {
    const userEmail = thunkApi.getState().userSlice.user.email;
    body.user = userEmail;
    if (!body.title) body.title = body.book;

    toast.info(
      "Making items in dynamo. This takes about 10 seconds per 100 items."
    );
    const res = await invokeLambda(
      LAMBDA_ARNS.readerUtils,
      "batchPutDynamo",
      body
    );
    toast.success("Done uploading new items. Please refresh page.");
    return res;
  }
);

export const updateMessageGeneration = createAsyncThunk(
  "message/update/generation",
  async ({ id, messageId, generation }) => {
    // await invokeLambda(LAMBDA_ARNS.readerUtils, "updateMessageGeneration", {
    //   id: questionId,
    //   message,
    // });
    return { id, messageId, generation };
  }
);

export const questionSlice = createSlice({
  name: "questionSlice",
  initialState: {
    questions: [],
    question: null,
  },
  reducers: {
    setquestion: (state, action) => {
      state.question = action.payload;
    },
    setSelections: (state, action) => {
      state.selections = action.payload;
    },
    setIsEditing: (state, action) => {
      const { id, isEditing } = action.payload;
      state.questions.find((n) => {
        if (n.id === id) {
          n.isEditing = isEditing;
        }
      });
    },
    setMessagesForQuestion: (state, action) => {
      const { id, messages } = action.payload;
      const q = state.questions.find((n) => n.id === id);
      q.messages = messages;
    },
    addAnswer: (state, action) => {
      const answer = action.payload;
      const q = state.questions.find((n) => n.id === answer.questionId);
      if (!q.answers) q.answers = [];
      q.answers.push(answer);
    },
    updateMessage: (state, action) => {
      const { questionId, message } = action.payload;
      const questionIndex = state.questions.findIndex(
        (n) => n.id === questionId
      );
      const messageIndex = state.questions[questionIndex].messages.findIndex(
        (n) => n.id === message.id
      );
      state.questions[questionIndex].messages[messageIndex] = message;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(questionsGet.fulfilled, (state, action) => {
      state.questions = action.payload;
    });
    builder.addCase(questionAdd.fulfilled, (state, action) => {
      state.questions.push(action.payload);
    });
    builder.addCase(questionAddAnswer.fulfilled, (state, action) => {
      const { id, items } = action.payload;
      state.questions.forEach((item, index, array) => {
        if (item.id === id) {
          item.messages.concat(items);
        }
      });
    });
    builder.addCase(removeMessage.fulfilled, (state, action) => {
      // state.questions.push(action.payload);
      const { questionId, messageId } = action.payload;
      const questionIndex = state.questions.findIndex(
        (n) => n.id === questionId
      );
      const messageIndex = state.questions[questionIndex].messages.findIndex(
        (n) => n.id === messageId
      );
      state.questions[questionIndex].messages.splice(messageIndex, 1);
    });
    builder.addCase(updateMessageAsync.fulfilled, (state, action) => {
      const { questionId, message, generation } = action.payload;
      const questionIndex = state.questions.findIndex(
        (n) => n.id === questionId
      );
      const messageIndex = state.questions[questionIndex].messages.findIndex(
        (n) => n.id === message.id
      );
      state.questions[questionIndex].messages[messageIndex].history[
        generation
      ] = message.history[generation];
    });
    builder.addCase(updateMessageGeneration.fulfilled, (state, action) => {
      const { id, messageId, generation } = action.payload;
      const questionIndex = state.questions.findIndex((n) => n.id === id);
      const messageIndex = state.questions[questionIndex].messages.findIndex(
        (n) => n.id === messageId
      );
      state.questions[questionIndex].messages[messageIndex].generation =
        generation;
    });
    builder.addCase(batchPutDynamo.fulfilled, (state, action) => {
      const items = action.payload;
      state.questions.concat(items);
    });
  },
});

export const {
  setIsEditing,
  addAnswer,
  setMessagesForQuestion,
  updateMessage,
} = questionSlice.actions;
export default questionSlice.reducer;
