import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, post } from "aws-amplify/api";
import { v4 } from "uuid";

export const blurbsGet = createAsyncThunk("blurbs/get", async () => {
  const res = await get({
    apiName: "readerapi",
    path: "/blurbs",
  }).response;
  const text = await res.body.text();
  const data = JSON.parse(text);
  return data;
});
export const blurbsAdd = createAsyncThunk("blurbs/add", async (body) => {
  body.id = v4();
  await post({
    apiName: "readerapi",
    path: "/blurbs",
    options: { body },
  }).response;
  return body;
});
export const blurbRemove = createAsyncThunk("blurb/remove", async (id) => {
  await del({
    apiName: "readerapi",
    path: `/blurbs/object/${id}`,
  });
  return id;
});

export const blurbSlice = createSlice({
  name: "blurbSlice",
  initialState: {
    blurbs: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(blurbsGet.fulfilled, (state, action) => {
      state.blurbs = action.payload;
    });
    builder.addCase(blurbsAdd.fulfilled, (state, action) => {
      state.blurbs.push(action.payload);
    });
    builder.addCase(blurbRemove.fulfilled, (state, action) => {
      state.blurbs.splice(
        state.blurbs.findIndex((n) => n.id === action.payload),
        1
      );
    });
  },
});

// export const { } = blurbSlice.actions;
export default blurbSlice.reducer;
