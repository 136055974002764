import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Pagination,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { aiRespondUsingEntireThread } from "../features/openaiSlice";
import {
  questionAddAnswer,
  removeMessage,
  setIsEditing,
  updateMessageAsync,
  updateMessageGeneration,
} from "../features/questionSlice";

export default function ConversationList({ questions, book }) {
  const dispatch = useDispatch();
  const [responseModel, setResponseModel] = useState({});
  const [isEditingMessage, setIsEditingMessage] = useState({ id: -1 });
  // const [question, setQuestion] = useState(null);
  const [page, setPage] = useState(1);

  if (!questions.length) {
    return (
      <Card>
        <CardHeader title="No Convos" />
        <CardContent>
          {`You haven't added any conversations to ${book} yet. Or your filters are too tight.`}
        </CardContent>
      </Card>
    );
  }

  const {
    id,
    user_input,
    isEditing = false,
    messages = [],
    user: questionUser = "anonymous",
  } = questions[page - 1];

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex flex-column">
        <Pagination
          count={questions.length}
          defaultPage={page}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(_, page) => {
            // setQuestion(questions[page - 1]);
            setPage(page);
          }}
        />
      </div>
      <Card>
        <div className="d-flex align-items-center">
          <CardHeader
            sx={{ fontSize: 20 }}
            // title={book.substring(0, book.lastIndexOf("."))}
            // subheader={`${questionUser} asks -  ${question}`}
            subheader={book.substring(0, book.lastIndexOf("."))}
          />
          <div className="w-100 d-flex justify-content-end">
            <Button
              title="Add your own response"
              variant="text"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => {
                dispatch(setIsEditing({ id, isEditing: true }));
              }}
            >
              Add Response
            </Button>
          </div>
        </div>
        <CardContent>
          <div className="d-flex ml-2 flex-column">
            {messages.map((_message, key) => {
              // console.log("_message", _message);
              const {
                history,
                generation,
                id: messageId,
                user: answerUser = "anonymous",
                role,
              } = _message;
              const message = history[generation];
              let {
                chatCompletion,
                content,
                model = "",
                similarity = null,
              } = message;
              if (similarity === 0) similarity = null;

              const isEditing = messageId === isEditingMessage.id;

              if (chatCompletion) {
                content = chatCompletion.choices[0].message.content;
              }
              if (answerUser.includes("@")) {
                answerUser = answerUser.substring(
                  0,
                  answerUser.lastIndexOf("@")
                );
              }
              return (
                <Accordion key={key}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <small
                          style={{
                            color: "#7966ab",
                          }}
                          title="The user of this response"
                        >
                          - {role}
                        </small>

                        {role === "assistant" && (
                          <>
                            <Button
                              title="Generate an AI response to the question using the model selected above"
                              variant="text"
                              size="small"
                              style={{ textTransform: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                dispatch(
                                  aiRespondUsingEntireThread({
                                    id,
                                    messageId,
                                  })
                                );
                              }}
                            >
                              Generate AI
                              {/* {generation === -1
                                ? `Make AI Response`
                                : "Generate AI Response"} */}
                            </Button>
                          </>
                        )}
                      </div>

                      <q style={{ fontSize: 14, margin: 10 }}>
                        {content.substring(0, 100)}
                      </q>
                      {/* <FontAwesomeIcon icon={faEllipsis} /> */}

                      {role === "assistant" && (
                        <div className="d-flex justify-content-between align-items-end">
                          <div>
                            {generation > -1 && (
                              <Chip
                                color="primary"
                                size="small"
                                label={`generation: ${generation + 1}`}
                              />
                            )}
                            {model.length > 0 && (
                              <div title={model} className="mt-1">
                                <Chip
                                  color="primary"
                                  size="small"
                                  label={model}
                                />
                              </div>
                            )}

                            {similarity !== null && (
                              <div title={similarity} className="mt-1">
                                <Chip
                                  color="primary"
                                  size="small"
                                  label={`Similarity Score: ${similarity.toFixed(
                                    4
                                  )}`}
                                />
                              </div>
                            )}
                          </div>
                          {history.length > 1 && (
                            <Pagination
                              count={history.length}
                              page={generation + 1}
                              variant="outlined"
                              shape="rounded"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              onChange={(_, page) => {
                                dispatch(
                                  updateMessageGeneration({
                                    id,
                                    messageId,
                                    generation: page - 1,
                                  })
                                );
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {messageId === isEditingMessage.id ? (
                      <div
                        title="Click to edit"
                        className="d-flex flex-column w-100 cursor-pointer"
                      >
                        <TextField
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            // padding: "10px",
                            resize: "none",
                            width: "100%",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "14px",
                            lineHeight: "1.5",
                            color: "#333",
                            // backgroundColor: "#f8f8f8",
                            boxShadow: "inset 0 1px 8px rgba(0,0,0,0.07)",
                            marginBottom: "10px",
                          }}
                          multiline
                          minRows={3}
                          // maxRows={5}
                          value={isEditingMessage.content}
                          onChange={(e) =>
                            setIsEditingMessage({
                              ...message,
                              content: e.target.value,
                            })
                          }
                        />
                      </div>
                    ) : (
                      <>
                        <small> click to edit</small>
                        <div
                          title="Click to edit"
                          className="d-flex flex-column w-100"
                          style={{ fontSize: 14, cursor: "pointer" }}
                          onClick={() => setIsEditingMessage(message)}
                        >
                          {content}
                        </div>
                      </>
                    )}

                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                    {/* <Button
                                  variant="text"
                                  size="small"
                                  style={{ textTransform: "none" }}
                                  onClick={() => {
                                    console.log(selections);
                                  }}
                                >
                                  add citation
                                </Button> */}
                    {/* </div> */}
                  </AccordionDetails>

                  {isEditing === true && (
                    <AccordionActions>
                      <Button
                        style={{ flexGrow: 2 }}
                        className="justify-content-start"
                        // icon={faTrash}
                        title="Remove this item"
                        // size="2x"
                        // cursor={"pointer"}
                        onClick={() => {
                          setIsEditingMessage({ id: -1 });
                          dispatch(
                            removeMessage({ messageId, questionId: id })
                          );
                        }}
                      >
                        Remove
                      </Button>
                      <Button
                        // icon={faTrash}
                        title="Remove this item"
                        // size="2x"
                        // cursor={"pointer"}
                        onClick={() => {
                          setIsEditingMessage({ id: -1 });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        // icon={faTrash}
                        title="Remove this item"
                        // size="2x"
                        // cursor={"pointer"}
                        onClick={() => {
                          setIsEditingMessage({ id: -1 });
                          dispatch(
                            updateMessageAsync({
                              message: isEditingMessage,
                              questionId: id,
                              generation,
                            })
                          );
                        }}
                      >
                        Save
                      </Button>
                    </AccordionActions>
                  )}
                  <Divider />
                </Accordion>
              );
            })}
          </div>

          {isEditing && (
            <div>
              <TextField
                label="your comment"
                multiline
                // rows={4}
                variant="standard"
                fullWidth
                value={responseModel.answer}
                onChange={(e) => {
                  setResponseModel({
                    ...responseModel,
                    answer: e.target.value,
                  });
                }}
              />
            </div>
          )}

          {/* <Button type="text">Add Citation</Button> */}
        </CardContent>
        <CardActions className="d-flex justify-content-end">
          {isEditing ? (
            <>
              <Button
                type="text"
                size="small"
                onClick={() => {
                  if (!responseModel.answer.length) {
                    console.log("answer cannot be empty");
                    return;
                  }
                  responseModel.s3BookKey = book;
                  dispatch(
                    questionAddAnswer({
                      id,
                      answer: responseModel,
                    })
                  );
                  setResponseModel({});
                  dispatch(setIsEditing({ id, isEditing: false }));
                }}
              >
                Save
              </Button>

              <Button
                type="text"
                size="small"
                onClick={() => {
                  dispatch(setIsEditing({ id, isEditing: false }));
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>{/* <Button type="text">Generate Response</Button> */}</>
          )}
        </CardActions>
      </Card>
    </div>
  );
}
