import { downloadData, getUrl } from "aws-amplify/storage";
import dayjs from "dayjs";
import { Lambda } from "aws-sdk";
import { fetchAuthSession } from "aws-amplify/auth";

export const LAMBDA_ARNS = {
  readerUtils: "arn:aws:lambda:us-east-1:402564586804:function:readerUtils-dev",
};

import relativeTime from "dayjs/plugin/relativeTime";
import { toast } from "react-toastify";
dayjs.extend(relativeTime);

export const fetchDogManifest = async (id) => {
  // if (this.manifestCache[id]) {
  //   return this.manifestCache[id];
  // }

  // fetch manifest from s3
  const Key = `${id}/Editing/manifest_v2.json`;
  const manifest = await s3GetObject(Key);
  return manifest;
};

export const getBucketName = () => {
  // For this app just use dev
  const stage = "development";
  return `${stage}-murphy-cares`;
};
export const s3GetSignedUrl = async (key) => {
  try {
    const presignedUrl = await getUrl({
      key,
      options: { accessLevel: "public" },
    });
    return presignedUrl.url.href;
  } catch (err) {
    console.log(err);
  }
};
export const s3GetObject = async (key) => {
  try {
    const res = await downloadData({ key }).result;
    const body = await res.body.text();
    return JSON.parse(body);
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const getAllVideos = (manifest) => {
  // Get all videos from manifest
  const filesInside = manifest.inside.map((action) => {
    return action.videosList.map((videosList) => {
      return videosList.xvid.map((xvid) => {
        return xvid.name;
      });
    });
  });
  const filesOutside = manifest.outside.map((action) => {
    return action.videosList.map((videosList) => {
      return videosList.xvid.map((xvid) => {
        return xvid.name;
      });
    });
  });

  const allVideos = Array.from(
    new Set(
      filesInside
        .flat(Infinity)
        .concat(filesOutside.flat(Infinity))
        .filter((n) => !n.startsWith("$"))
    )
  );
  return allVideos;
};

export const makePrettyDate = (t) => {
  return dayjs(t).fromNow();
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const sterilizeKey = (key) => {
  return key.replace(".", "+++");
};
export const unSterilizeKey = (key) => {
  return key.replace("+++", ".");
};

export const invokeLambda = async (arn, action, body) => {
  if (!action) {
    toast.error(
      "This method requires action param to be not empty. The aciton is the name of a function inside the lambda your invoking."
    );
  }
  try {
    const authSession = await fetchAuthSession();
    const lambda = new Lambda({
      credentials: authSession.credentials,
      region: "us-east-1",
    });
    const params = {
      FunctionName: arn,
      InvocationType: "RequestResponse",
      Payload: JSON.stringify({
        action,
        body,
      }),
    };
    const { Payload } = await lambda.invoke(params).promise();
    return JSON.parse(Payload);
  } catch (err) {
    console.error("Error invoking Lambda function:", err);
  }
};
