import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "aws-amplify/api";
// import OpenAI from "openai";
import { toast } from "react-toastify";
import { addAnswer, updateMessage } from "./questionSlice";
// import { Auth } from "aws-amplify";
import { LAMBDA_ARNS, invokeLambda } from "../utils";

export const aiRespondUsingEntireThread = createAsyncThunk(
  "ai/respond/using/entire/thread",
  async ({ id, messageId }, thunkApi) => {
    toast.info("I'm thinking about what I want to say, just a moment...");
    const model = thunkApi.getState().modelSlice.model;
    if (!model || model === "none") {
      toast.warn("Please select a model from the dropdown.");
      console.log("no model selected");
      return;
    }
    try {
      const res = await invokeLambda(LAMBDA_ARNS.readerUtils, "ask", {
        id,
        model,
        messageId,
      });

      toast.success("Done thinking!");
      thunkApi.dispatch(updateMessage({ questionId: id, message: res }));
    } catch (err) {
      console.log(err);
    }
  }
);

export const aiAskQuestion = createAsyncThunk(
  "ai/ask/with/model",
  async ({ questionData }, thunkApi) => {
    const model = thunkApi.getState().modelSlice.model;
    if (!model || model === "none") {
      toast.warn("Please select a model from the dropdown.");
      console.log("no model selected");
      return;
    }

    const operation = post({
      apiName: "readerapi",
      path: "/ai",
      options: {
        body: { questionData, model },
      },
    });
    const { body } = await operation.response;
    const data = await body.json();
    const answer = data.data;
    answer.answer = chatCompletion.choices[0].message.content;

    toast.success(
      "I'm doing some serious thinking, check back in about 30 seconds."
    );

    // Add this answer to the questionsSlice
    thunkApi.dispatch(addAnswer(answer));
  }
);

export const openaiSlice = createSlice({
  name: "openaiSlice",
  initialState: {
    responses: [],
    chat: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(aiAskQuestion.fulfilled, (state, action) => {
      debugger;
      // state.responses.push(action.payload);
    });
    builder.addCase(aiAskQuestion.pending, (state, action) => {
      debugger;
      // state.responses.push(action.payload);
    });
    builder.addCase(aiAskQuestion.rejected, (state, action) => {
      debugger;
      // state.responses.push(action.payload);
    });
  },
});

// export const { setBook } = openaiSlice.actions;
export default openaiSlice.reducer;
