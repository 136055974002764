import { Button } from "@mui/material";
import React from "react";
import { json2csv } from "json-2-csv";

export default function DialogExportCSVScores({ questions }) {
  const data = questions
    .map((question) => {
      return question.messages.map((masterMessage) => {
        const userQuestion = question.messages[1].content;
        return masterMessage.history
          .filter((n) => n.model)
          .map(({ similarity, model, content }) => {
            return {
              model,
              similarity,
              // source: masterMessage.history[0].content,
              user: userQuestion,
              // generated: content,
            };
          });
      });
    })
    .flat()
    .filter((n) => n.length)
    .flat();
  // let data = questions
  //   .map((question) => {
  //     return question.messages.map((masterMessage) => {
  //       // const system = question.messages[0].content;
  //       const userQuestion = question.messages[1].content;
  //       return masterMessage.history
  //         .filter((n) => n.model)
  //         .map(({ similarity, model, content }) => {
  //           return {
  //             // source: masterMessage.history[0].content,
  //             // generated: content,
  //             user: userQuestion,
  //             similarity,
  //             model,
  //           };
  //         });
  //     });
  //   })
  //   .flat()
  //   .filter((n) => n.length)
  //   .flat();

  // data = data.map(({ user, similarity, model }) => {
  //   return {
  //     [user]: { similarity, model },
  //   };
  // });

  // let id = 1;
  // const nothing = {};
  // data = data.reduce((prev, { user, model, similarity }, index, array) => {
  //   if (!nothing[user]) {
  //     nothing[user] = "something";
  //     prev[id] = [];
  //     id++;
  //   }
  //   prev[id].push({ model, similarity });
  //   return prev;
  // }, {});

  // const obj = {};
  // let j = 0;
  // for (let i = 0; i < data.length; i++) {
  //   const { user, model, similarity } = data[i];
  //   if (!obj[user]) {
  //     obj[user] = user;
  //     j++;
  //   }
  //   if (!obj[j.toString()]) {
  //     obj[j.toString()] = [];
  //   }
  //   obj[j.toString()].push(data[i]);
  // }

  // console.log("data", obj);

  // const col = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const exportQuestionsToCSV = async () => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(data[0]);
    let csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    let csvArray = csv.join("\r\n");

    // const csv = await json2csv(newData, {});

    // Create a blob of the data
    let blob = new Blob([csvArray], {
      type: "text/csv;charset=utf-8;",
    });

    // Create a link and download
    let link = document.createElement("a");
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `similarity-${new Date().toISOString()}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Button
      variant="text"
      size="small"
      style={{ textTransform: "none" }}
      onClick={() => {
        exportQuestionsToCSV();
      }}
    >
      Export Scores CSV
    </Button>
  );
}
