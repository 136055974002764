import React from "react";
import Reader from "./Reader";
import SelectionDrawer from "./SelectionDrawer";

export default () => {
  return (
    // <div className="container">
    <div className="d-flex">
      <div className="col-5">
        <SelectionDrawer />
      </div>
      <div className="col-7">
        <Reader />
      </div>
    </div>
  );
};
