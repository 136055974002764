import { faBook, faSignOut, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  TextField,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setBook } from "../features/bookSlice";
import { toggleSelectionDrawer } from "../features/uiSlice";
import {
  getMyModels,
  modelAdd,
  modelRemove,
  setModel,
} from "../features/modelSlice";

const Header = () => {
  const { books, book } = useSelector((state) => state.bookSlice);
  const [currentBook, setCurrentBook] = useState("nada");
  const [isAddModelDialogOpen, setIsAddModelDialogOpen] = useState(false);
  const [dialogModel, setDialogModel] = useState({});
  const { models, model } = useSelector((state) => state.modelSlice);

  useEffect(() => {
    dispatch(getMyModels());
  }, []);

  const dispatch = useDispatch();
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <div className="navbar-brand">AI Reader</div>

        {/* <Select
          sx={{ minWidth: 400, backgroundColor: "white", color: "#333" }}
          // label="Select Book"
          placeholder="Select a Book"
          value={book ? book.key : ""}
          onChange={(e) => {
            if (!e) return;
            dispatch(setBook(e.target.value));
          }}
          style={{ marginLeft: "10px" }}
        >
          {books.map((book, key) => {
            return (
              <MenuItem key={key} value={book.key}>
                {book.key}
              </MenuItem>
            );
          })}
        </Select> */}
        <FormControl
          size="small"
          sx={{ minWidth: 300, backgroundColor: "white" }}
        >
          {/* <InputLabel id="demo-simple-select-label">Select a book</InputLabel> */}
          <Select
            // labelId="demo-simple-select-label"
            // id="demo-simple-select"
            value={book}
            // label="Select a book"
            onChange={(e) => {
              dispatch(setBook(e.target.value));
            }}
          >
            <MenuItem key="none" value="none">
              Select a book
            </MenuItem>
            {books.map((book, key) => {
              return (
                <MenuItem key={key} value={book.key}>
                  {book.key}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl
          size="small"
          sx={{
            display: "flex",
            minWidth: 500,
            backgroundColor: "white",
            ml: 1,
          }}
        >
          <Select
            value={model}
            placeholder="Select a model"
            onChange={(e) => {
              const value = e.target.value;
              if (value === "addNew") {
                setIsAddModelDialogOpen(true);
              } else {
                dispatch(setModel(value));
              }
            }}
          >
            <MenuItem key="none" value="none">
              Select a model
            </MenuItem>
            {/* <MenuItem key="addNew" value="addNew">
              Add new model
            </MenuItem> */}
            {models.toReversed().map((_model, key) => {
              const { id } = _model;
              return (
                <MenuItem key={key} value={id}>
                  <ListItemText>{id}</ListItemText>
                  {/* {model !== name && (
                    <FontAwesomeIcon
                      icon={faTrash}
                      cursor={"pointer"}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(modelRemove(id));
                      }}
                    />
                  )} */}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {/* <select
          label="Select Book"
          // value={selectedBook}
          onChange={(e) => setSelectedBook(e.target.value)}
          style={{ marginLeft: "10px" }}
        >
          {books.map((book, key) => (
            <option key={key} value={book.key}>
              {book.key}
            </option>
          ))}
        </select> */}

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto" style={{ gap: 20 }}>
            {/* <li className="nav-item">
              <a
                className="nav-link {{ 'active' if request.endpoint == 'landing_page' else '' }}"
                href="/"
              >
                Load Book
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a
                className="nav-link {{ 'active' if request.endpoint == 'llms_page' else '' }}"
                href="/llm"
              >
                LLM
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link {{ 'active' if request.endpoint == 'tools_page' else '' }}"
                href="/tools"
              >
                Tools
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link {{ 'active' if request.endpoint == 'manage_agents' else '' }}"
                href="/agents"
              >
                Hire Resources
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a
                className="nav-link {{ 'active' if request.endpoint == 'manage_tasks' else '' }}"
                href="{{ url_for('manage_tasks') }}"
              >
                Create Tasks
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a
                className="nav-link {{ 'active' if request.endpoint == 'manage_crews' else '' }}"
                href="{{ url_for('manage_crews') }}"
              >
                Manage Crew
              </a>
            </li> */}
            {/* <li className="nav-item">
              <FontAwesomeIcon
                color="white"
                icon={faBook}
                onClick={() => dispatch(toggleSelectionDrawer())}
                cursor="pointer"
                title="Your Selections"
              />
            </li> */}
            <li className="nav-item">
              <FontAwesomeIcon
                color="white"
                icon={faSignOut}
                title="Signout"
                onClick={() => window.signOut()}
                cursor="pointer"
              />
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-cog"></i>
              </a>
            </li> */}
          </ul>
        </div>
      </div>

      {isAddModelDialogOpen && (
        <Dialog
          fullWidth
          open={true}
          onClose={() => setIsAddModelDialogOpen(false)}
        >
          <DialogTitle>Add new Model</DialogTitle>
          <DialogContent>
            <TextField
              title="This is the model that will be used when the python ai lambda is hit."
              fullWidth
              label="Model name"
              variant="outlined"
              value={dialogModel.name || ""}
              placeholder="Model name"
              onChange={(e) => {
                setDialogModel({
                  name: e.target.value,
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={() => {
                if (!dialogModel.name) {
                  console.error("name can't be empty");
                  return;
                }
                dispatch(modelAdd(dialogModel));
                setIsAddModelDialogOpen(false);
              }}
            >
              Save
            </Button>
            <Button
              variant="text"
              onClick={() => {
                setIsAddModelDialogOpen(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </nav>
  );
};

export default Header;
