import { withAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes } from "aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import config from "./amplifyconfiguration.json";
import Header from "./comps/Header";
import router from "./router.jsx";
import { useEffect } from "react";
import { booksGet } from "./features/bookSlice.js";
import { useDispatch } from "react-redux";
import { setUser } from "./features/userSlice.js";
Amplify.configure(config);
Amplify.configure({
  ...Amplify.getConfig(),
  Storage: {
    S3: {
      region: "us-east-1",
      bucket: "reader-2024-library",
    },
  },
});

function App({ signOut }) {
  window.signOut = signOut;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(booksGet());
    (async () => {
      const attribs = await fetchUserAttributes();
      dispatch(setUser(attribs));
    })();
  }, []);

  return (
    <>
      <Header />
      <RouterProvider router={router} />
    </>
  );
}

export default withAuthenticator(App);
