// TODO add kaag_edit as ideal response
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { batchPutDynamo, questionsGet } from "../features/questionSlice";
import ConversationList from "./ConversationList";
import DialogNewConversation from "./dialogs/DialogNewConversation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport } from "@fortawesome/free-solid-svg-icons";
import DialogImportJSONL from "./dialogs/DialogImportJSONL";
import DialogExportJSONL from "./dialogs/DialogExportJSONL";
import DialogExportCSVScores from "./dialogs/DialogExportCSVScores";

// const exportToCSV = (questions) => {
//   const replacer = (key, value) => (value === null ? "" : value);
//   const header = Object.keys(questions[0]);
//   let csv = questions.map((row) =>
//     header
//       .map((fieldName) => JSON.stringify(row[fieldName], replacer))
//       .join(",")
//   );
//   csv.unshift(header.join(","));
//   let csvArray = csv.join("\r\n");

//   // Create a blob of the data
//   let blob = new Blob([csvArray], {
//     type: "text/csv;charset=utf-8;",
//   });

//   // Create a link and download
//   let link = document.createElement("a");
//   let url = URL.createObjectURL(blob);
//   link.setAttribute("href", url);
//   link.setAttribute("download", "questions.csv");
//   link.style.visibility = "hidden";
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

export default () => {
  return (
    <div style={{ height: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <BasicTabs />
      </div>
    </div>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BasicTabs = () => {
  const [value, setValue] = useState(0);
  const [isAddQuestionDialogOpen, setIsAddQuestionDialogOpen] = useState(false);
  const questions = useSelector((state) => state.questionSlice.questions);
  const dispatch = useDispatch();
  const book = useSelector((state) => state.bookSlice.book);
  const [filterByDataset, setFilterByDataset] = useState("");
  const [datasets, setDatasets] = useState([]);

  React.useEffect(() => {
    dispatch(questionsGet());
  }, []);

  React.useEffect(() => {
    const _datasets = [...new Set(questions.map((n) => n.dataset))];
    setDatasets(_datasets);
  }, [questions]);

  React.useEffect(() => {
    const filteredByBook = questions.filter((question) => {
      return question.book === book;
    });
  }, [book]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderConversation = () => {
    if (book === "none") return <></>;
    const _questions = questions
      .filter((n) => {
        return n.book === book;
      })
      .filter((q) => {
        return q.dataset === filterByDataset;
      });
    return <ConversationList questions={_questions} book={book} />;
  };

  const questionsForExport = questions
    .filter((n) => {
      return n.book === book;
    })
    .filter((q) => {
      return q.dataset === filterByDataset;
    });

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Conversations" {...a11yProps(0)} />
          {/* <Tab label="About AI Models" {...a11yProps(1)} />
          <Tab label="Personality" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div>
          {book === "none" ? (
            <div>Select a book above</div>
          ) : (
            <div>
              <div className=" mb-2 w-100 d-flex justify-content-end">
                <DialogImportJSONL />

                {questionsForExport.length > 0 && (
                  <div>
                    <DialogExportJSONL questions={questionsForExport} />
                    <DialogExportCSVScores questions={questionsForExport} />
                  </div>
                )}

                <Button
                  variant="text"
                  size="small"
                  style={{ textTransform: "none" }}
                  onClick={() => {
                    setIsAddQuestionDialogOpen(true);
                  }}
                >
                  Add Conversation
                </Button>
              </div>

              <FormControl
                fullWidth
                size="small"
                sx={{ minWidth: 300, backgroundColor: "white", padding: 1 }}
              >
                <InputLabel id="demo-simple-select-label">
                  Select Dataset
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  // id="demo-simple-select"
                  value={filterByDataset}
                  label="Select Dataset"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFilterByDataset(e.target.value);
                  }}
                >
                  {datasets.map((n, key) => {
                    return (
                      <MenuItem key={key} value={n}>
                        {n}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {renderConversation()}
            </div>
          )}
        </div>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        <ModelBlurbs />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <p style={{ fontSize: 14 }}>
          Ideas You are a Literary Expert, a philosopher and a special kind of
          scholar known as a Rebinder. You have a database of your own dictated
          analyses of sections of Henry David Thoreau's Walden along with
          excerpts from the primary source text of Walden. Your job is to use
          VERBATIM excerpts of transcripts of these dictations to hold a
          book-club style conversation about Walden. Primary source and
          transcript excerpts that are relevant to the conversation will be
          provided to you by a retrieval mechanism. This context will be
          identified as TRANSCRIPT EXCERPTS, QUESTION GENERATION TRANSCRIPT
          EXCERPTS or PRIMARY SOURCE EXCERPTS.\n 1. FOR TRANSCRIPT EXCERPTS: Use
          as much verbatim language as possible from each transcript excerpt. Do
          not ever indicate that transcript excerpts are quotes (only PRIMARY
          SOURCE EXCERPTS should be in block quotes). All generated text must
          mimic the style of the excerpt using words and phrases found in the
          excerpt: NEVER use transition words or phrases that do not exist in
          the excerpt.\n *IMPORTANT* never leave out a personal anecdote if the
          excerpt contains one, and always use first-person if the excerpt uses
          the first person. \n 2. FOR QUESTION GENERATION TRANSCRIPT EXCERPTS:
          At the end of the response, ask the user a question -- but only if you
          are instructed to do so. Use the QUESTION GENERATION TRANSCRIPT
          EXCERPT as context to ask the user a critically engaging question to
          drive the conversation forward. The question should be relevant, using
          language from the excepts, but also surprising and offbeat.\n3. FOR
          PRIMARY SOURCE EXCERPTS: Assume you selected the PRIMARY SOURCE
          EXCERPT, not the user. Never state or imply the the user selected the
          excerpt. Start by tying the excerpt to the user's input question or
          statement, focusing on key terms or phrases in the passage that have
          complex or ambiguous meanings.
        </p>
      </CustomTabPanel> */}
      {isAddQuestionDialogOpen && (
        <DialogNewConversation
          onClose={() => setIsAddQuestionDialogOpen(null)}
        />
      )}
    </Box>
  );
};
