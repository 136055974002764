import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { questionAdd } from "../../features/questionSlice";
import { setBook } from "../../features/bookSlice";
import { v4 } from "uuid";

export default function DialogNewConversation({ onClose }) {
  const dispatch = useDispatch();
  const books = useSelector((state) => state.bookSlice.books);
  const [model, setModel] = useState({
    title: "",
    book: "",
    messages: [
      {
        role: "system",
        content: "",
        id: v4(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      },
    ],
  });
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Start a new conversation</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          value={model.title || ""}
          onChange={(e) => {
            setModel({ ...model, title: e.target.value });
          }}
        />
        <Select
          fullWidth
          value={model.book || ""}
          onChange={(e) => {
            setModel({ ...model, book: e.target.value });
            dispatch(setBook(e.target.value));
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" disabled>
            Select a Book
          </MenuItem>
          {books.map((book, index) => (
            <MenuItem key={index} value={book.key}>
              {book.key}
            </MenuItem>
          ))}
        </Select>
        <TextField
          margin="dense"
          id="model"
          label="Personality"
          type="text"
          fullWidth
          multiline
          rows={10}
          value={model.messages[0].content || ""}
          onChange={(e) => {
            setModel({
              ...model,
              messages: [{ ...model.messages[0], content: e.target.value }],
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          title="Generate an AI response to the question using the model selected above"
          variant="text"
          size="small"
          style={{ textTransform: "none" }}
          onClick={() => {
            if (!model.title || !model.messages[0].content || !model.book) {
              alert("All fields must be filled out");
              return;
            }
            dispatch(questionAdd({ question: model }));
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
