import { useEffect, useRef, useState } from "react";
import { ReactReader } from "react-reader";
import { useDispatch, useSelector } from "react-redux";
import { getUrl } from "aws-amplify/storage";
import { setSelections } from "../features/bookSlice";

export default () => {
  const dispatch = useDispatch();
  const viewerRef = useRef(null);
  const [location, setLocation] = useState(0);
  const [signedUrl, setSignedUrl] = useState("");
  const [rendition, setRendition] = useState(undefined);
  const selections = useSelector((state) => state.bookSlice.selections);
  const [selection, setSelection] = useState(null);
  const book = useSelector((state) => state.bookSlice.book);

  useEffect(() => {
    // TODO get signed s3 url to book
    if (!book || book === "none") return;

    (async () => {
      const res = await getUrl({
        key: book,
        options: {
          accessLevel: "public", // can be 'private', 'protected', or 'guest' but defaults to `guest`
          // targetIdentityId?: 'XXXXXXX', // id of another user, if `accessLevel` is `guest`
          // validateObjectExistence: false,  // defaults to false
          // expiresIn: 20 // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
          // useAccelerateEndpoint?: true; // Whether to use accelerate endpoint.
        },
      });
      // console.log("signed URL: ", res.url);
      // console.log("URL expires at: ", res.expiresAt);
      setSignedUrl(res.url.toString());
    })();
  }, [book]);

  useEffect(() => {
    if (!rendition) return;

    const setRenderSelection = (cfiRange, contents) => {
      if (!rendition) return;
      // const newSelections = selections.concat({
      //   text: rendition.getRange(cfiRange).toString(),
      //   cfiRange,
      // });
      console.log(selections);
      dispatch(
        setSelections([
          {
            text: rendition.getRange(cfiRange).toString(),
            cfiRange,
          },
        ])
      );
      // dispatch(setSelections(newSelections));
      // setSelections((list) =>
      //   list.concat({
      //     text: rendition.getRange(cfiRange).toString(),
      //     cfiRange,
      //   })
      // );
      // );
      rendition.annotations.add("highlight", cfiRange, {}, undefined, "hl", {
        fill: "red",
        "fill-opacity": "0.5",
        "mix-blend-mode": "multiply",
      });
      const selection = contents.window.getSelection();
      selection.removeAllRanges();
    };
    rendition.on("selected", setRenderSelection);
    return () => {
      rendition.off("selected", setRenderSelection);
    };
  }, [setSelections, rendition]);

  return (
    <div style={{ height: "100vh" }}>
      {signedUrl && (
        <ReactReader
          ref={viewerRef}
          url={signedUrl}
          location={location}
          locationChanged={(loc) => setLocation(loc)}
          getRendition={(rend) => {
            console.log(rend);
            setRendition(rend);
          }}
          epubOptions={{
            allowPopups: true, // Adds `allow-popups` to sandbox-attribute
            allowScriptedContent: true, // Adds `allow-scripts` to sandbox-attribute
          }}
        />
      )}
    </div>
  );
};
