import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { list } from "aws-amplify/storage";

// Get a list of books on s3
export const booksGet = createAsyncThunk("books/get", async () => {
  try {
    const objects = await list({ level: "public" });
    return objects.items
      .map((n) => {
        delete n.lastModified;
        return n;
      })
      .filter((n) => n.key)
      .filter((n) => n.key.toLowerCase().indexOf(".epub") > -1);
  } catch (error) {
    console.error("Error fetching public objects:", error);
    throw error;
  }
});

export const bookGet = createAsyncThunk("book/get", async ({ book }) => {
  try {
    const epubUrl = await Storage.get(`public/${agent}.epub`, {
      download: false,
    });
    return { agent, epubUrl };
  } catch (error) {
    console.error("Error fetching epub file:", error);
    throw error;
  }
});

// export const setSelections = createAsyncThunk("books/get", async () => {
//   try {
//     const objects = await list({ level: "public" });
//     return objects.items.map((n) => {
//       delete n.lastModified;
//       return n;
//     });
//   } catch (error) {
//     console.error("Error fetching public objects:", error);
//     throw error;
//   }
// });

export const bookSlice = createSlice({
  name: "bookSlice",
  initialState: {
    books: [],
    book: "none",
    bookData: null,
    selections: [],
  },
  reducers: {
    setBook: (state, action) => {
      state.book = action.payload;
    },
    setSelections: (state, action) => {
      state.selections = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(booksGet.fulfilled, (state, action) => {
      state.books = action.payload.filter((n) => n.key);
    });
    builder.addCase(bookGet.fulfilled, (state, action) => {
      state.book = action.payload;
    });
  },
});

export const { setBook, setSelections } = bookSlice.actions;
export default bookSlice.reducer;
