import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "uiSlice",
  initialState: {
    isSelectionDrawerOpen: false,
  },
  reducers: {
    toggleSelectionDrawer: (state, action) => {
      state.isSelectionDrawerOpen = !state.isSelectionDrawerOpen;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(openaiGet.fulfilled, (state, action) => {
    //   state.responses.push(action.payload);
    // });
  },
});

export const { toggleSelectionDrawer } = uiSlice.actions;
export default uiSlice.reducer;
