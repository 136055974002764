// import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
// import config from "./amplifyconfiguration.json";
import App from "./App.jsx";
import "./index.css";
import store from "./store.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// console.log("env", import.meta.env);
// import { getBucketName } from "./utils";

// Amplify.configure(config);
// console.log("bucket name", getBucketName());
// Amplify.configure({
//   ...Amplify.getConfig(),
//   Storage: {
//     S3: {
//       region: "us-east-1",
//       bucket: getBucketName(),
//     },
//   },
// });

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </React.StrictMode>
);
